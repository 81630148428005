import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _01611b33 = () => interopDefault(import('../src/pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _46da2af6 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _0b483afc = () => interopDefault(import('../src/pages/sign_in.vue' /* webpackChunkName: "pages/sign_in" */))
const _572fde11 = () => interopDefault(import('../src/pages/initial-settings/company-profile.vue' /* webpackChunkName: "pages/initial-settings/company-profile" */))
const _26c72acd = () => interopDefault(import('../src/pages/initial-settings/invite-members.vue' /* webpackChunkName: "pages/initial-settings/invite-members" */))
const _2c644930 = () => interopDefault(import('../src/pages/initial-settings/preferences.vue' /* webpackChunkName: "pages/initial-settings/preferences" */))
const _7588651f = () => interopDefault(import('../src/pages/initial-settings/user-profile.vue' /* webpackChunkName: "pages/initial-settings/user-profile" */))
const _35d8cd4c = () => interopDefault(import('../src/pages/sessions/callback.vue' /* webpackChunkName: "pages/sessions/callback" */))
const _30a2dae0 = () => interopDefault(import('../src/pages/sessions/invitation.vue' /* webpackChunkName: "pages/sessions/invitation" */))
const _9976e73a = () => interopDefault(import('../src/pages/contents/_uuid/index.vue' /* webpackChunkName: "pages/contents/_uuid/index" */))
const _181ae185 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds" */))
const _e8ea8cd4 = () => interopDefault(import('../src/pages/companies/_company_id/construction-kinds/new.vue' /* webpackChunkName: "pages/companies/_company_id/construction-kinds/new" */))
const _ca3927ba = () => interopDefault(import('../src/pages/companies/_company_id/customers.vue' /* webpackChunkName: "pages/companies/_company_id/customers" */))
const _0bb0c534 = () => interopDefault(import('../src/pages/companies/_company_id/customers/new.vue' /* webpackChunkName: "pages/companies/_company_id/customers/new" */))
const _c40cf75e = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/index" */))
const _3cc850db = () => interopDefault(import('../src/pages/companies/_company_id/customers/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/customers/_id/edit" */))
const _178ed65e = () => interopDefault(import('../src/pages/companies/_company_id/expiration.vue' /* webpackChunkName: "pages/companies/_company_id/expiration" */))
const _5367a732 = () => interopDefault(import('../src/pages/companies/_company_id/members.vue' /* webpackChunkName: "pages/companies/_company_id/members" */))
const _e54db612 = () => interopDefault(import('../src/pages/companies/_company_id/profile.vue' /* webpackChunkName: "pages/companies/_company_id/profile" */))
const _985bbdc2 = () => interopDefault(import('../src/pages/companies/_company_id/projects/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/index" */))
const _211afad0 = () => interopDefault(import('../src/pages/companies/_company_id/reports/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/index" */))
const _7b78fe1e = () => interopDefault(import('../src/pages/companies/_company_id/setting.vue' /* webpackChunkName: "pages/companies/_company_id/setting" */))
const _dd8f2b60 = () => interopDefault(import('../src/pages/companies/_company_id/sharing-contents/index.vue' /* webpackChunkName: "pages/companies/_company_id/sharing-contents/index" */))
const _7f515116 = () => interopDefault(import('../src/pages/companies/_company_id/unavailability.vue' /* webpackChunkName: "pages/companies/_company_id/unavailability" */))
const _81a25d6e = () => interopDefault(import('../src/pages/companies/_company_id/user-profile.vue' /* webpackChunkName: "pages/companies/_company_id/user-profile" */))
const _6119b42d = () => interopDefault(import('../src/pages/companies/_company_id/projects/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/new" */))
const _0e402c1a = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/index" */))
const _4d63df47 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id" */))
const _7730d6ec = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/index" */))
const _6324f1f5 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/index" */))
const _60de0c49 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/detail.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/detail" */))
const _4eb23212 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/member.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/member" */))
const _2a0e76f7 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/reports.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/reports" */))
const _4f3a5ad2 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/index" */))
const _3376c283 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/new.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/new" */))
const _74e89b0e = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/posts/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/posts/index" */))
const _5449a16b = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/fullscreen" */))
const _0bf930c8 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/preview" */))
const _7bea2ff0 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/print/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/print/index" */))
const _3634a018 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/index" */))
const _21349036 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/schedule/view/fullscreen.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/schedule/view/fullscreen" */))
const _16c05399 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/index" */))
const _b093435c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/index" */))
const _b8730eda = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/edit" */))
const _72158ac1 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/board_posts/_board_id/files/index" */))
const _8e109a8c = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/edit" */))
const _35825448 = () => interopDefault(import('../src/pages/companies/_company_id/projects/_id/files/_file_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/projects/_id/files/_file_id/preview" */))
const _495a2dbb = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/index.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/index" */))
const _cae4a20e = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/edit" */))
const _3b1108ae = () => interopDefault(import('../src/pages/companies/_company_id/files/_id/preview.vue' /* webpackChunkName: "pages/companies/_company_id/files/_id/preview" */))
const _6357d231 = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/edit.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/edit" */))
const _7a5c461b = () => interopDefault(import('../src/pages/companies/_company_id/reports/_id/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/reports/_id/pdf" */))
const _5a52ed87 = () => interopDefault(import('../src/pages/companies/_company_id/project/_id/schedule/pdf.vue' /* webpackChunkName: "pages/companies/_company_id/project/_id/schedule/pdf" */))
const _19f132a7 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/companies",
      component: _01611b33,
      name: "companies"
    }, {
      path: "/logout",
      component: _46da2af6,
      name: "logout"
    }, {
      path: "/sign_in",
      component: _0b483afc,
      name: "sign_in"
    }, {
      path: "/initial-settings/company-profile",
      component: _572fde11,
      name: "initial-settings-company-profile"
    }, {
      path: "/initial-settings/invite-members",
      component: _26c72acd,
      name: "initial-settings-invite-members"
    }, {
      path: "/initial-settings/preferences",
      component: _2c644930,
      name: "initial-settings-preferences"
    }, {
      path: "/initial-settings/user-profile",
      component: _7588651f,
      name: "initial-settings-user-profile"
    }, {
      path: "/sessions/callback",
      component: _35d8cd4c,
      name: "sessions-callback"
    }, {
      path: "/sessions/invitation",
      component: _30a2dae0,
      name: "sessions-invitation"
    }, {
      path: "/contents/:uuid?",
      component: _9976e73a,
      name: "contents-uuid"
    }, {
      path: "/companies/:company_id/construction-kinds",
      component: _181ae185,
      name: "companies-company_id-construction-kinds",
      children: [{
        path: "new",
        component: _e8ea8cd4,
        name: "companies-company_id-construction-kinds-new"
      }]
    }, {
      path: "/companies/:company_id/customers",
      component: _ca3927ba,
      name: "companies-company_id-customers",
      children: [{
        path: "new",
        component: _0bb0c534,
        name: "companies-company_id-customers-new"
      }, {
        path: ":id",
        component: _c40cf75e,
        name: "companies-company_id-customers-id"
      }, {
        path: ":id/edit",
        component: _3cc850db,
        name: "companies-company_id-customers-id-edit"
      }]
    }, {
      path: "/companies/:company_id/expiration",
      component: _178ed65e,
      name: "companies-company_id-expiration"
    }, {
      path: "/companies/:company_id/members",
      component: _5367a732,
      name: "companies-company_id-members"
    }, {
      path: "/companies/:company_id/profile",
      component: _e54db612,
      name: "companies-company_id-profile"
    }, {
      path: "/companies/:company_id/projects",
      component: _985bbdc2,
      name: "companies-company_id-projects"
    }, {
      path: "/companies/:company_id/reports",
      component: _211afad0,
      name: "companies-company_id-reports"
    }, {
      path: "/companies/:company_id/setting",
      component: _7b78fe1e,
      name: "companies-company_id-setting"
    }, {
      path: "/companies/:company_id/sharing-contents",
      component: _dd8f2b60,
      name: "companies-company_id-sharing-contents"
    }, {
      path: "/companies/:company_id/unavailability",
      component: _7f515116,
      name: "companies-company_id-unavailability"
    }, {
      path: "/companies/:company_id/user-profile",
      component: _81a25d6e,
      name: "companies-company_id-user-profile"
    }, {
      path: "/companies/:company_id/projects/new",
      component: _6119b42d,
      name: "companies-company_id-projects-new"
    }, {
      path: "/companies/:company_id/files/:id?",
      component: _0e402c1a,
      name: "companies-company_id-files-id"
    }, {
      path: "/companies/:company_id/projects/:id",
      component: _4d63df47,
      children: [{
        path: "",
        component: _7730d6ec,
        name: "companies-company_id-projects-id"
      }, {
        path: "board_posts",
        component: _6324f1f5,
        name: "companies-company_id-projects-id-board_posts"
      }, {
        path: "detail",
        component: _60de0c49,
        name: "companies-company_id-projects-id-detail"
      }, {
        path: "member",
        component: _4eb23212,
        name: "companies-company_id-projects-id-member"
      }, {
        path: "reports",
        component: _2a0e76f7,
        name: "companies-company_id-projects-id-reports"
      }, {
        path: "schedule",
        component: _4f3a5ad2,
        name: "companies-company_id-projects-id-schedule"
      }, {
        path: "board_posts/new",
        component: _3376c283,
        name: "companies-company_id-projects-id-board_posts-new"
      }, {
        path: "board_posts/posts",
        component: _74e89b0e,
        name: "companies-company_id-projects-id-board_posts-posts"
      }, {
        path: "schedule/fullscreen",
        component: _5449a16b,
        name: "companies-company_id-projects-id-schedule-fullscreen"
      }, {
        path: "schedule/preview",
        component: _0bf930c8,
        name: "companies-company_id-projects-id-schedule-preview"
      }, {
        path: "schedule/print",
        component: _7bea2ff0,
        name: "companies-company_id-projects-id-schedule-print"
      }, {
        path: "schedule/view",
        component: _3634a018,
        name: "companies-company_id-projects-id-schedule-view"
      }, {
        path: "schedule/view/fullscreen",
        component: _21349036,
        name: "companies-company_id-projects-id-schedule-view-fullscreen"
      }, {
        path: "board_posts/:board_id",
        component: _16c05399,
        name: "companies-company_id-projects-id-board_posts-board_id"
      }, {
        path: "files/:file_id",
        component: _b093435c,
        name: "companies-company_id-projects-id-files-file_id"
      }, {
        path: "board_posts/:board_id/edit",
        component: _b8730eda,
        name: "companies-company_id-projects-id-board_posts-board_id-edit"
      }, {
        path: "board_posts/:board_id/files",
        component: _72158ac1,
        name: "companies-company_id-projects-id-board_posts-board_id-files"
      }, {
        path: "files/:file_id/edit",
        component: _8e109a8c,
        name: "companies-company_id-projects-id-files-file_id-edit"
      }, {
        path: "files/:file_id/preview",
        component: _35825448,
        name: "companies-company_id-projects-id-files-file_id-preview"
      }]
    }, {
      path: "/companies/:company_id/reports/:id",
      component: _495a2dbb,
      name: "companies-company_id-reports-id"
    }, {
      path: "/companies/:company_id/files/:id?/edit",
      component: _cae4a20e,
      name: "companies-company_id-files-id-edit"
    }, {
      path: "/companies/:company_id/files/:id?/preview",
      component: _3b1108ae,
      name: "companies-company_id-files-id-preview"
    }, {
      path: "/companies/:company_id/reports/:id/edit",
      component: _6357d231,
      name: "companies-company_id-reports-id-edit"
    }, {
      path: "/companies/:company_id/reports/:id/pdf",
      component: _7a5c461b,
      name: "companies-company_id-reports-id-pdf"
    }, {
      path: "/companies/:company_id/project/:id?/schedule/pdf",
      component: _5a52ed87,
      name: "companies-company_id-project-id-schedule-pdf"
    }, {
      path: "/",
      component: _19f132a7,
      name: "index"
    }],

    fallback: false
  })
}
