export var LocalStorageKeys;
(function (LocalStorageKeys) {
    LocalStorageKeys["JWT"] = "jwt";
    LocalStorageKeys["RefreshToken"] = "refresh_token";
    LocalStorageKeys["DeviceUUID"] = "device_uuid";
    LocalStorageKeys["LastURL"] = "lastUrl";
    LocalStorageKeys["RedirectUrl"] = "redirectUrl";
    LocalStorageKeys["Departments"] = "departments";
    // チュートリアル表示フラグ
    LocalStorageKeys["FilesFirstAccess"] = "filesFirstAccess";
    LocalStorageKeys["ConstructionKindsFirstAccess"] = "constructionKindsFirstAccess";
    LocalStorageKeys["CustomersFirstAccess"] = "customersFirstAccess";
    LocalStorageKeys["ProjectsFirstAccess"] = "projectsFirstAccess";
    LocalStorageKeys["ProjectsLastQuery"] = "projectsLastQuery";
    LocalStorageKeys["ContentCommentUserName"] = "content_comment_user_name";
    LocalStorageKeys["RedirectLoopStopKey"] = "redirectLoopStopKey";
    // 画像連携設定表示フラグ
    LocalStorageKeys["ImageLinkSettingShowed"] = "imageLinkSettingShowed";
})(LocalStorageKeys || (LocalStorageKeys = {}));
export var DepartmentCode;
(function (DepartmentCode) {
    DepartmentCode["CAREECON"] = "careecon";
    DepartmentCode["CAREECON_SITES"] = "careecon_sites";
})(DepartmentCode || (DepartmentCode = {}));
export var DepartmentURL;
(function (DepartmentURL) {
    DepartmentURL["HTTPS_HEADER"] = "https://";
    DepartmentURL["CAREECON_BASE_LINK"] = "careecon.jp/";
    DepartmentURL["SITES_BASE_LINK"] = "start.eat.auto/";
    DepartmentURL["SAIYO_BASE_LINK"] = "recruit-cms.careecon.jp/";
    DepartmentURL["KEIEI_BASE_LINK"] = "plus.careecon.jp/";
})(DepartmentURL || (DepartmentURL = {}));
export var FirestoreCollectionNames;
(function (FirestoreCollectionNames) {
    FirestoreCollectionNames["MESSAGES"] = "messages";
    FirestoreCollectionNames["NOTIFICATIONS"] = "notifications";
    FirestoreCollectionNames["UPLOAD_CONTENTS"] = "upload_contents";
    FirestoreCollectionNames["STATUS_CONTENT"] = "status_content";
    FirestoreCollectionNames["USER_BADGES"] = "user_badges";
    FirestoreCollectionNames["USER_NOTIFICATION_SETTINGS"] = "user_notification_settings";
    FirestoreCollectionNames["STATUS_PROJECT_PLACES"] = "status_project_places";
    FirestoreCollectionNames["STATUS_CONTENT_ZIP_GENERATE"] = "status_content_zip_generate";
    FirestoreCollectionNames["STATUS_SCHEDULE_PDF_GENERATE"] = "status_schedule_pdf_generate";
    FirestoreCollectionNames["STATUS_REPORT_PDF_GENERATE"] = "status_report_pdf_generate";
    FirestoreCollectionNames["STATUS_BOARD_POST"] = "status_board_post";
})(FirestoreCollectionNames || (FirestoreCollectionNames = {}));
export var RootMutationTypes;
(function (RootMutationTypes) {
    RootMutationTypes["INIT_STATE"] = "INIT_STATE";
    RootMutationTypes["TOGGLE_INFO_BAR"] = "TOGGLE_INFO_BAR";
    RootMutationTypes["ENQUEUE_DOWNLOAD"] = "ENQUEUE_DOWNLOAD";
    RootMutationTypes["COMPLETED_QUEUE"] = "COMPLETED_QUEUE";
    RootMutationTypes["FAILED_QUEUE"] = "FAILED_QUEUE";
    RootMutationTypes["CLEAR_QUEUE"] = "CLEAR_QUEUE";
})(RootMutationTypes || (RootMutationTypes = {}));
export var DownloadType;
(function (DownloadType) {
    DownloadType[DownloadType["schedule"] = 0] = "schedule";
    DownloadType[DownloadType["report"] = 1] = "report";
    DownloadType[DownloadType["content"] = 2] = "content";
})(DownloadType || (DownloadType = {}));
